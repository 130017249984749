import { Controller } from '@hotwired/stimulus'
import Tagify from '@yaireo/tagify'

export default class extends Controller {
  static targets = ['input']

  connect () {
    this.render()
  }

  render () {
    const tagify = new Tagify(this.inputTarget, { originalInputValueFormat: (valuesArr) => valuesArr.map((item) => item.value).join(', ') })

    // window.tagify = tagify;
    let controller

    const that = this

    tagify.on('input', (event) => {
      const { value } = event.detail
      tagify.settings.whitelist.length = 0 // reset the whitelist

      // https://developer.mozilla.org/en-US/docs/Web/API/AbortController/abort
      controller && controller.abort()
      controller = new AbortController()

      if (that.inputTarget.dataset.tagsPath) {
        tagify.loading(true).dropdown.hide.call(tagify)

        fetch(`${that.inputTarget.dataset.tagsPath}?query=${value}`, { signal: controller.signal })
          .then((RES) => RES.json())
          .then((whitelist) => {
            tagify.settings.whitelist.splice(0, whitelist.length, ...whitelist)
            tagify.loading(false).dropdown.show.call(tagify, value)
          })
      }
    })
  }

  disconnect () {
    // window.tagify.removeAllTags();
    const classes = this.element.getElementsByClassName('tagify')
    Array.from(classes).forEach((e) => e.remove())
  }
}
