import * as Turbo from '@hotwired/turbo'
import { Controller } from '@hotwired/stimulus'

export default class FormChangeUrlController extends Controller {
  connect () {
    this.element.addEventListener('turbo:before-fetch-response', (event) => {
      const { url } = event.detail.fetchResponse.response
      Turbo.navigator.history.push(new URL(url))
    })
  }
}
