import { Controller } from '@hotwired/stimulus'
import Inputmask from 'inputmask'

export default class InputmaskController extends Controller {
  static values = { type: String }

  initialize () {
    if (this.typeValue === 'date') {
      Inputmask({ mask: '99/99/9999', placeholder: 'mm/dd/yyyy' }).mask(this.element)
    }

    if (this.typeValue === 'currency') {
      Inputmask({ alias: 'currency', rightAlign: false }).mask(this.element)
    }
  }
}
