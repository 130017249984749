import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['timeOffAllowanceInput', 'unlimitedCheckbox', 'sickAllowanceInput', 'sickUnlimitedCheckbox']

  static values = { unlimited: Boolean, sickUnlimited: Boolean }

  connect () {
    this.toggleClass = this.data.get('class') || 'hidden'
    this.unlimitedValue = this.unlimitedCheckboxTarget.checked
    this.sickUnlimitedValue = this.sickUnlimitedCheckboxTarget.checked
  }

  changed () {
    this.unlimitedValue = !this.unlimitedValue
  }

  sickChanged () {
    this.sickUnlimitedValue = !this.sickUnlimitedValue
  }

  unlimitedValueChanged () {
    if (this.unlimitedValue === true) {
      this.timeOffAllowanceInputTarget.disabled = true
      this.timeOffAllowanceInputTarget.parentNode.classList.add(this.toggleClass)
    } else {
      this.timeOffAllowanceInputTarget.disabled = false
      this.timeOffAllowanceInputTarget.parentNode.classList.remove(this.toggleClass)
    }
  }

  sickUnlimitedValueChanged () {
    if (this.sickUnlimitedValue === true) {
      this.sickAllowanceInputTarget.disabled = true
      this.sickAllowanceInputTarget.parentNode.classList.add(this.toggleClass)
    } else {
      this.sickAllowanceInputTarget.disabled = false
      this.sickAllowanceInputTarget.parentNode.classList.remove(this.toggleClass)
    }
  }
}
