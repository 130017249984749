import Chart from 'stimulus-chartjs'
import Clipboard from 'stimulus-clipboard'
import Autosave from 'stimulus-rails-autosave'
import ScrollTo from 'stimulus-scroll-to'
import TextareaAutogrow from 'stimulus-textarea-autogrow'
import { registerControllers } from 'stimulus-vite-helpers'
import {
  Dropdown,
  Modal
} from 'tailwindcss-stimulus-components'

import { stimulus } from '~/init'

const controllers = import.meta.glob('~/controllers/**/*_controller.js', { eager: true })
import.meta.glob('../../components/**/*.{js,css}', { eager: true })

stimulus.register('autosave', Autosave)
stimulus.register('chart', Chart)
stimulus.register('clipboard', Clipboard)
stimulus.register('dropdown', Dropdown)
stimulus.register('modal', Modal)
stimulus.register('scroll-to', ScrollTo)
stimulus.register('textarea-autogrow', TextareaAutogrow)
registerControllers(stimulus, controllers)
