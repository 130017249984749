import { Controller } from '@hotwired/stimulus'
import tippy from 'tippy.js'

export default class TippyController extends Controller {
  static values = { content: String }

  initialize () {
    tippy(this.element, {
      content: this.contentValue,
      allowHTML: true
    })
  }
}
