import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['timeOffAllowanceInput', 'unlimitedCheckbox', 'carryoverDaysContainer', 'carryoverCheckbox', 'policiesGroup', 'carryoverDays', 'carryover', 'isWaitingPeriod', 'probationDaysInput', 'probationDaysGroup']

  static values = { unlimited: Boolean, carryoverAllowed: Boolean, isWaitingPeriod: Boolean }

  connect () {
    this.toggleClass = this.data.get('class') || 'hidden'
    this.unlimitedValue = this.unlimitedCheckboxTarget.checked
    this.carryoverAllowedValue = this.carryoverCheckboxTarget.checked

    this.isWaitingPeriodValue = this.probationDaysInputTarget.value !== '0' && this.probationDaysInputTarget.value !== ''
    this.isWaitingPeriodTarget.checked = this.isWaitingPeriodValue
  }

  changed () {
    this.unlimitedValue = !this.unlimitedValue
  }

  isWaitingPeriodChanged () {
    this.isWaitingPeriodValue = !this.isWaitingPeriodValue
    if (this.isWaitingPeriodValue === false) {
      this.probationDaysInputTarget.value = '0'
    }
  }

  carryoverChanged () {
    this.carryoverAllowedValue = !this.carryoverAllowedValue
  }

  unlimitedValueChanged () {
    if (this.unlimitedValue === true) {
      // this.carryoverContainerTarget.classList.add(this.toggleClass);

      this.timeOffAllowanceInputTarget.disabled = true
      this.timeOffAllowanceInputTarget.parentNode.classList.add(this.toggleClass)
      this.carryoverCheckboxTarget.disabled = true

      this.carryoverCheckboxTarget.disabled = true
      this.carryoverCheckboxTarget.parentNode.classList.add(this.toggleClass)
      this.carryoverCheckboxTarget.checked = false

      this.carryoverAllowedValue = false
    } else {
      // this.carryoverContainerTarget.classList.remove(this.toggleClass);
      this.timeOffAllowanceInputTarget.disabled = false
      this.timeOffAllowanceInputTarget.parentNode.classList.remove(this.toggleClass)
      this.carryoverCheckboxTarget.disabled = false
      this.carryoverCheckboxTarget.parentNode.classList.remove(this.toggleClass)
    }
  }

  isWaitingPeriodValueChanged () {
    if (this.isWaitingPeriodValue === true) {
      this.probationDaysGroupTarget.classList.remove(this.toggleClass)
    } else {
      this.probationDaysGroupTarget.classList.add(this.toggleClass)
    }
  }

  carryoverAllowedValueChanged () {
    if (this.carryoverAllowedValue === true) {
      this.showCarryoverDaysTarget()
      this.carryoverDaysContainerTarget.classList.remove(this.toggleClass)
    } else {
      this.hideCarryoverDaysTarget()
      this.carryoverDaysContainerTarget.classList.add(this.toggleClass)
    }
  }

  hideCarryoverDaysTarget () {
    this.carryoverDaysTarget.disabled = true
    this.carryoverDaysTarget.parentNode.classList.add(this.toggleClass)
  }

  showCarryoverDaysTarget () {
    this.carryoverDaysTarget.disabled = false
    this.carryoverDaysTarget.parentNode.classList.remove(this.toggleClass)
  }
}
