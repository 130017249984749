import { Controller } from '@hotwired/stimulus'
import { loadStripe } from '@stripe/stripe-js'

export default class StripeController extends Controller {
  static targets = ['form']

  static values = {
    secret: String,
    publicKey: String
  }

  async connect () {
    const stripe = await loadStripe(this.publicKeyValue)

    const elements = this.formTarget.stripeElements = stripe.elements({
      clientSecret: this.secretValue,
      loader: 'always'
    })

    const paymentElement = elements.create('payment')
    const addressElement = elements.create('address', {
      mode: 'billing'
    })
    const payment = document.createElement('div')
    const address = document.createElement('div')
    paymentElement.mount(payment)
    addressElement.mount(address)

    address.classList.add('mt-4')

    this.formTarget.appendChild(payment)
    this.formTarget.appendChild(address)

    // paymentElement.once('ready', () => {
    //   // TODO: hide loading here
    // });

    this.element.addEventListener('turbo:before-fetch-request', async (e) => {
      e.preventDefault()
      // TODO: show loading here

      const result = await stripe.confirmSetup({
        elements: this.formTarget.stripeElements,
        redirect: 'if_required',
        confirmParams: {
          return_url: window.location.href
        }
      })

      if (result.error) {
        // show error
        return
      }

      const paymentMethodId = result.setupIntent.payment_method
      e.detail.fetchOptions.body.set('payment_method_id', paymentMethodId)
      e.detail.resume()
    })
  }
}
