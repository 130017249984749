import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['calculationMethod', 'workingDaysHint', 'calendarDaysHint']

  connect () {
    this.calculationMethodTarget.value === 'calendar_days' ? this.showCalendarDaysHint() : this.showWorkingDaysHint()
  }

  changeCalculationMethod () {
    this.calculationMethodTarget.value === 'calendar_days' ? this.showCalendarDaysHint() : this.showWorkingDaysHint()
  }

  showWorkingDaysHint () {
    this.calendarDaysHintTarget.classList.add('hidden')
    this.workingDaysHintTarget.classList.remove('hidden')
  }

  showCalendarDaysHint () {
    this.workingDaysHintTarget.classList.add('hidden')
    this.calendarDaysHintTarget.classList.remove('hidden')
  }
}
