// import data from '@emoji-mart/data';
import { Controller } from '@hotwired/stimulus'
import { Picker } from 'emoji-mart'
// import { init } from 'emoji-mart';
import { useClickOutside } from 'stimulus-use'

export default class extends Controller {
  static targets = ['picker', 'button', 'input']

  static values = { open: Boolean }

  connect () {
    // init({ data });

    useClickOutside(this)
    this.toggleClass = this.data.get('class') || 'hidden'

    this.picker = new Picker({
      previewPosition: 'none',
      navPosition: 'none',
      // searchPosition: 'none',
      theme: 'light',
      onEmojiSelect: (emoji) => {
        this.buttonTarget.innerHTML = emoji.native
        this.inputTarget.value = emoji.shortcodes
        this.toggle()
      }
    })

    this.pickerTarget.appendChild(this.picker)
  }

  disconnect () {
    this.openValue = false
  }

  toggle () {
    this.openValue = !this.openValue
  }

  clickOutside () {
    this.openValue = false
  }

  openValueChanged () {
    if (this.openValue) {
      this.show()
    } else {
      this.hide()
    }
  }

  show () {
    this.pickerTarget.classList.remove(this.toggleClass)
  }

  hide () {
    this.pickerTarget.classList.add(this.toggleClass)
  }
}
