import { Toggle } from 'tailwindcss-stimulus-components'

export default class ToggleController extends Toggle {
  static targets = ['elToggle']

  toggleAndHideElToggle (event) {
    this.elToggleTarget.classList.add('hidden')
    this.toggle(event)
  }

  toggleAndShowElToggle (event) {
    this.elToggleTarget.classList.remove('hidden')
    this.toggle(event)
  }
}
