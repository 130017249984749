import { Controller } from '@hotwired/stimulus'

import { useDynamicFields } from '../../mixins/useDynamicFields'

export default class extends Controller {
  static targets = [
    'typeInput',
    'approversChoice',
    'approversList',
    'periodFieldset',
    'periodDays',
    'submitButton',
    'noRequiredApproversError'
  ]

  connect () {
    useDynamicFields(this)
    const self = this

    if (this.hasTypeInputTarget) {
      this.typeInputTarget.addEventListener(
        'addItem',
        function (event) {
          const { half_day_allowed, requires_approval, can_select_approvers, has_approvers } = event.detail.customProperties
          self.checkApproversView(requires_approval, can_select_approvers, has_approvers)
          self.checkPeriodFieldset(half_day_allowed)
          self.checkSubmitButton(requires_approval, can_select_approvers, has_approvers)
        },
        false
      )
    }
  }

  checkApproversView(requires_approval, can_select_approvers, has_approvers) {
    // Show only if requires approvers and cann't select them and has default approvers
    this.approversListTarget.disabled = !(requires_approval && !can_select_approvers && has_approvers);
    // Show only if requires approvers and can select them
    this.approversChoiceTarget.disabled = !(requires_approval && can_select_approvers);
    // Show only if requires approvers, cann't select them, and there are no default approvers
    this.noRequiredApproversErrorTarget.disabled = !(requires_approval && !can_select_approvers && !has_approvers)
  }

  checkPeriodFieldset (value) {
    if (value === true) {
      this.periodFieldsetTarget.disabled = false
    } else {
      this.periodFieldsetTarget.disabled = true

      // If change type to type that half_day_allowed==false, we need to enable period days instead of parts.
      this.periodDaysTarget.checked = true
      this.enable({ target: this.periodDaysTarget })
    }
  }

  checkSubmitButton(requires_approval, can_select_approvers, has_approvers) {
    // Disable button if member cann't select approvers and has no approvers at all
    this.submitButtonTarget.disabled = requires_approval && !can_select_approvers && !has_approvers;
  }
}
