import RangePlugin from 'flatpickr/dist/plugins/rangePlugin'

// import { Russian } from 'flatpickr/dist/l10n/ru';
import Flatpickr from '../flatpickr_controller'

export default class TimeOffDatesController extends Flatpickr {
  connect () {
    const firstDayOfWeek = document.head.querySelector('meta[name=first_day_of_week]').content

    this.config = {
      // mode: 'range',
      locale: { firstDayOfWeek },
      // dateFormat: 'Y-m-d',
      altFormat: 'M j, Y',
      showMonths: 1,
      altInput: true,
      disableMobile: true,
      // allowInput: true,
      plugins: [new RangePlugin({ input: '#time_off_end_date' })]
      // onChange: [function(selectedDates){
      //   const dateArr = selectedDates.map(date => this.formatDate(date, 'Y-m-d'));
      //   this.element.value = dateArr[0];
      // }]
    }

    super.connect()
  }
}
