import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['scrollContainer', 'currentMember', 'member', 'slug']

  connect () {
    const GAP = 20

    if (this.hasCurrentMemberTarget) {
      const currentMemberDimention = this.currentMemberTarget.getBoundingClientRect()
      const thisElementDimention = this.scrollContainerTarget.getBoundingClientRect()
      const scrollTop = currentMemberDimention.top - thisElementDimention.top - GAP
      this.scrollContainerTarget.scrollTop = scrollTop
    }
  }

  setCurrent (e) {
    this.memberTargets.forEach((t) => t.classList.remove('hover:bg-teal-50', 'bg-teal-50'))

    const anchor = e.target.closest('a')
    this.slugTarget.value = anchor.dataset.slug

    anchor.classList.add('hover:bg-teal-50', 'bg-teal-50')
  }
}
