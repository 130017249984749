import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['slugField']

  change (event) {
    const { value } = event.target
    this.slugFieldTarget.value = value.toLowerCase().replace(/[^a-z0-9]/, '')
  }
}

/*
 * Usage
 * =====
 *
 * add data-controller="slugify" to common ancestor or form tag
 *
 * Action (add to the title input):
 * data-action="slugify#change"
 *
 * Target (add to the slug input):
 * data-slugify-target="slugField"
 *
 */
