export const useDynamicFields = controller => {
  Object.assign(controller, {
    enable ({ target }) {
      const elements = Array.from(this.element.elements)
      const selectedElements = 'selectedOptions' in target
        ? target.selectedOptions
        : [target]

      for (const element of elements.filter(element => element.name === target.name)) {
        if (element instanceof window.HTMLFieldSetElement) element.disabled = true
      }

      for (const element of controlledElements(...selectedElements)) {
        if (element instanceof window.HTMLFieldSetElement) element.disabled = false
      }
    }
  })
}

function controlledElements (...selectedElements) {
  return selectedElements.flatMap(selectedElement => {
    return getElementsByTokens(selectedElement.getAttribute('aria-controls'))
  })
}

function getElementsByTokens (tokens) {
  const ids = (tokens ?? '').split(/\s+/)

  return ids.map(id => document.getElementById(id))
}
